import React, { useState, useEffect } from 'react';
import { Row, Col, Badge } from 'antd';
// import TodayClass from './TodayClass';
// import OrderNum from './OrderNum';
// import StudentNum from './StudentNum';
import Chart from '@/components/Chart/Chart';
import LineOption from './chartConfig/chartLine';
import chartOrderLine from './chartConfig/chartOrderLine';
import BarOption from './chartConfig/chartBar';
import BarOption2 from './chartConfig/chartBar2';
import common_1 from '@/assets/img/common_1.png';
import common_2 from '@/assets/img/common_2.png';
import common_3 from '@/assets/img/common_3.png';
import common_4 from '@/assets/img/common_4.png';
import common_5 from '@/assets/img/common_5.png';
import common_6 from '@/assets/img/common_6.png';
import {
  get_agenda_item, get_order_count, get_class_count, get_student_count, get_class_hour_count,
} from './server';
import styles from './index.module.less';

const ShopPage = (props) => {
  const { history } = props;

  const [waitHandleTrial, setWaitHandleTrial] = useState(0);
  const [waitRevisit, setWaitRevisit] = useState(0);
  const [statOrder, setStatOrder] = useState({});
  const [statClassCount, setStatClassCount] = useState({});
  const [statStudentCount, setStatStudentCount] = useState({});
  const [statClassHourCount, setStatClassHourCount] = useState({});

  /* eslint-disable */
  useEffect(() => {
    getAgendaItem();
    getChartData();
  }, []);
  /* eslint-enable */

  const getAgendaItem = () => {
    get_agenda_item().then(({ code, data }) => {
      if (code === 0) {
        setWaitHandleTrial(data.waitHandleTrial);
        setWaitRevisit(data.waitRevisit);
      }
    });
  };

  const getChartData = () => {
    get_order_count().then(({ code, data }) => {
      if (code === 0) setStatOrder(data);
    });
    get_class_count().then(({ code, data }) => {
      if (code === 0) setStatClassCount(data);
    });
    get_student_count().then(({ code, data }) => {
      if (code === 0) setStatStudentCount(data);
    });
    get_class_hour_count().then(({ code, data }) => {
      if (code === 0) setStatClassHourCount(data);
    });
  };

  // 跳转
  const jump = (type) => {
    let url = '';
    switch (type) {
      case 'revisitManage':
        url = 'leads/revisitManage';
        break;
      case 'trialrecord':
        url = 'leads/trialrecord';
        break;
      case 'leadsManage':
        url = 'leads/leadsManage';
        break;
      case 'orderManage':
        url = 'order/orderManage';
        break;
      case 'students':
        url = 'teachItem/students';
        break;
      case 'teamManage':
        url = 'teachItem/teamManage';
        break;
      case 'scheduleManage':
        url = 'teachItem/scheduleManage';
        break;
      case 'classHourManage':
        url = 'teachItem/classHourManage';
        break;
      case 'orderstat':
        url = 'datacenter/orderstat';
        break;
      case 'studentstat':
        url = 'datacenter/studentstat';
        break;
      case 'classstat':
        url = 'datacenter/classstat';
        break;
      case 'classhourstat':
        url = 'datacenter/classhourstat';
        break;
      default:
        break;
    }
    history.push(`/main/${url}`);
  };

  return (
    <div className={styles.shopContainer}>
      {/* 今日课量 */}
      {/* <TodayClass /> */}
      {/* 订单数量 */}
      {/* <div className={styles.orderNumContainer}>
        <OrderNum />
      </div> */}
      {/* 学员数量 */}
      {/* <div className={styles.orderNumContainer}>
        <StudentNum />
      </div> */}
      <Row type="flex" justify="space-between" gutter={32}>
        <Col span={20} className={styles.operationalCont}>
          <span className={styles.title}>运营数据</span>
          <div className={styles.main}>
            <div className={styles.container}>
              <span className={styles.title}>{statOrder.title}</span>
              <span className={styles.more} onClick={() => jump('orderstat')}>更多</span>
              {statOrder.title && <Chart option={chartOrderLine(statOrder)} width="100%" height="100%" />}
            </div>
            <div className={styles.container}>
              <span className={styles.title}>{statStudentCount.title}</span>
              <span className={styles.more} onClick={() => jump('studentstat')}>更多</span>
              {statStudentCount.title && <Chart option={BarOption(statStudentCount)} width="100%" height="100%" />}
            </div>
            <div className={styles.container}>
              <span className={styles.title}>{statClassCount.title}</span>
              <span className={styles.more} onClick={() => jump('classstat')}>更多</span>
              {statClassCount.title && <Chart option={LineOption(statClassCount)} width="100%" height="100%" />}
            </div>
            <div className={styles.container}>
              <span className={styles.title}>{statClassHourCount.title}</span>
              <span className={styles.more} onClick={() => jump('classhourstat')}>更多</span>
              {statClassHourCount.title && <Chart option={BarOption2(statClassHourCount)} width="100%" height="100%" />}
            </div>
          </div>
        </Col>
        <Col span={4} className={styles.right}>
          <div className={styles.toDoListCont}>
            <span className={styles.title}>待办事项</span>
            <div className={styles.main}>
              <div className={styles.toDoItem} onClick={() => jump('revisitManage')}>
                <Badge color="#6A71DE" text="待回访" />
                <span>{waitRevisit}</span>
              </div>
              <div className={styles.toDoItem} onClick={() => jump('trialrecord')}>
                <Badge color="#6A71DE" text="试听待处理" />
                <span>{waitHandleTrial}</span>
              </div>
            </div>
          </div>
          <div className={styles.commonCont}>
            <span className={styles.title}>常用功能</span>
            <div className={styles.main}>
              <div className={styles.commonItem} onClick={() => jump('leadsManage')}>
                <img src={common_1} alt="线索管理" />
                <span>线索管理</span>
              </div>
              <div className={styles.commonItem} onClick={() => jump('orderManage')}>
                <img src={common_2} alt="订单管理" />
                <span>订单管理</span>
              </div>
              <div className={styles.commonItem} onClick={() => jump('students')}>
                <img src={common_3} alt="学员管理" />
                <span>学员管理</span>
              </div>
              <div className={styles.commonItem} onClick={() => jump('teamManage')}>
                <img src={common_4} alt="班级管理" />
                <span>班级管理</span>
              </div>
              <div className={styles.commonItem} onClick={() => jump('scheduleManage')}>
                <img src={common_5} alt="排课管理" />
                <span>排课管理</span>
              </div>
              <div className={styles.commonItem} onClick={() => jump('classHourManage')}>
                <img src={common_6} alt="报读列表" />
                <span>报读列表</span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ShopPage;
