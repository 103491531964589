import React, { Suspense } from 'react';
import { Redirect } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import PageLoading from '@/components/PageLoading';
import { hasLeftSidePath } from '@/config/path';

// 机构 icon
import { ReactComponent as ShopIcon } from '@/assets/img/shopIcon.svg';
// 教务管理
import { ReactComponent as TeachIcon } from '@/assets/img/teachIcon.svg';
// 课程管理
import { ReactComponent as ClassIcon } from '@/assets/img/classIcon.svg';
// 线上授课
// import { ReactComponent as OnlineClassIcon } from '@/assets/img/onlineClassIcon.svg';
// 订单管理
import { ReactComponent as OrderIcon } from '@/assets/img/orderIcon.svg';
// 学员管理
import { ReactComponent as StudentIcon } from '@/assets/img/studentIcon.svg';
// 教师管理
// import { ReactComponent as TeacherIcon } from '@/assets/img/teacherIcon.svg';
// 员工管理
// import { ReactComponent as StaffIcon } from '@/assets/img/staffIcon.svg';
// 教室管理
import { ReactComponent as ClassroomIcon } from '@/assets/img/classroomIcon.svg';
// 数据中心
import { ReactComponent as DataCenterIcon } from '@/assets/img/dataCenterIcon.svg';
// 设置
import { ReactComponent as SetIcon } from '@/assets/img/setIcon.svg';
// 线索
// import { ReactComponent as LeadsIcon } from '@/assets/img/leadsIcon.svg';
import { ReactComponent as FinancecenterIcon } from '@/assets/img/financecenterIcon.svg';


const lazy = CusComponent => <Suspense fallback={<PageLoading />}>{CusComponent}</Suspense>;
const RedirectTo = path => <Redirect to={path || `${hasLeftSidePath}/shop`} />;

// 机构管理
const ShopPage = React.lazy(() => import('../../views/main/shop'));

// 教务管理 教室管理
const ClassRoomPage = React.lazy(() => import('../../views/main/classRoom'));

// 教师管理
const TeacherPage = React.lazy(() => import('../../views/main/teacher/list'));

// 教师管理详情页
const TeacherDetailPage = React.lazy(() => import('../../views/main/teacher/detail'));

// 课程管理 课件管理
const CoursewarePage = React.lazy(() => import('../../views/main/course/courseware'));

// 课程管理 录播管理
const CourseVideoPage = React.lazy(() => import('../../views/main/course/courseVideo'));

// 课程大纲管理
const CourseOutlinePage = React.lazy(() => import('../../views/main/course/courseOutline/list'));

// 课程大纲管理详情页
const CourseOutlineDetailPage = React.lazy(() => import('../../views/main/course/courseOutline/detail'));

// 课程管理
const CourseManagePage = React.lazy(() => import('../../views/main/course/courseManage/list'));

// 课程管理新增编辑页
const CourseManageEditPage = React.lazy(() => import('../../views/main/course/courseManage/edit'));

// 课程管理详情页
const CourseManageDetailPage = React.lazy(() => import('../../views/main/course/courseManage/detail'));

// 线索管理
const LeadsPage = React.lazy(() => import('../../views/main/leads/leadsManage/list'));

// 线索管理详情页
const LeadsDetailPage = React.lazy(() => import('../../views/main/leads/leadsManage/detail'));

// 我的线索
const MyLeadsPage = React.lazy(() => import('../../views/main/leads/myLeads/list'));

// 采单管理
const InfoAcquisition = React.lazy(() => import('../../views/main/leads/infoAcquisition/list'));

// 我的采单管理
const MyInfoAcquisition = React.lazy(() => import('../../views/main/leads/myInfoAcquisition/list'));

// 采单编辑
const InfoAcquisitionEditPage = React.lazy(() => import('../../views/main/leads/infoAcquisition/edit'));

// 采单详情
const InfoAcquisitionDetailPage = React.lazy(() => import('../../views/main/leads/infoAcquisition/detail'));

// 线上招生
const OnlineShop = React.lazy(() => import('../../views/main/leads/onlineShop/list'));

// 机构信息
const OrgInfo = React.lazy(() => import('../../views/main/leads/onlineShop/orgInfo'));

// 线上售卖课程
const SaleCourse = React.lazy(() => import('../../views/main/leads/onlineShop/saleCourse'));

// 编辑课程信息
const EditCourse = React.lazy(() => import('../../views/main/leads/onlineShop/editCourse'));

// 秒杀活动
const SeckillList = React.lazy(() => import('../../views/main/leads/onlineShop/activity/seckill/list'));

// 编辑秒杀活动
const EditSeckill = React.lazy(() => import('../../views/main/leads/onlineShop/activity/seckill/edit'));

// 秒杀活动海报
const SeckillPoster = React.lazy(() => import('../../views/main/leads/onlineShop/activity/seckill/poster'));

// 秒杀活动数据
const SeckillData = React.lazy(() => import('../../views/main/leads/onlineShop/activity/seckill/data'));

// 拼团活动list
const PinTuanList = React.lazy(() => import('../../views/main/leads/onlineShop/activity/pintuan/list'));

// 编辑拼团活动
const EditPintuan = React.lazy(() => import('../../views/main/leads/onlineShop/activity/pintuan/edit'));

// 秒杀拼团海报
const PinTuanPoster = React.lazy(() => import('../../views/main/leads/onlineShop/activity/pintuan/poster'));

// 秒杀拼团数据
const PinTuanData = React.lazy(() => import('../../views/main/leads/onlineShop/activity/pintuan/data'));

// 报名活动list
const BaoMingList = React.lazy(() => import('../../views/main/leads/onlineShop/activity/baoming/list'));

// 编辑报名活动
const EditBaoMing = React.lazy(() => import('../../views/main/leads/onlineShop/activity/baoming/edit'));

// 报名海报
const BaoMingPoster = React.lazy(() => import('../../views/main/leads/onlineShop/activity/baoming/poster'));

// 报名数据
const BaoMingData = React.lazy(() => import('../../views/main/leads/onlineShop/activity/baoming/data'));

// 试听管理
const AuditionManage = React.lazy(() => import('../../views/main/leads/audition'));

// 回访管理
const RevisitManage = React.lazy(() => import('../../views/main/leads/revisit'));

// 家长管理
// const ParentsPage = React.lazy(() => import('../../views/main/student/parents'));

// 学生管理
const StudentsPage = React.lazy(() => import('../../views/main/student/students/list'));

// 学生管理详情页
const StudentsDetailPage = React.lazy(() => import('../../views/main/student/students/detail'));

// 我的学生管理
const MyStudentsPage = React.lazy(() => import('../../views/main/student/myStudents/list'));

/**
// 订单管理
const OrderPage = React.lazy(() => import('../../views/main/order/order/list'));

// 订单管理详情页
const OrderDetailPage = React.lazy(() => import('../../views/main/order/order/detail'));

// 订单管理支付页
const OrderPayPage = React.lazy(() => import('../../views/main/order/order/payPage'));

// 订单管理退费页
const OrderRefundPage = React.lazy(() => import('../../views/main/order/order/refundPage'));
 */

const HandlingcenterPage = React.lazy(() => import('../../views/main/handlingcenter/list'));
// 报名/续费/扩料
const OrderListPage = React.lazy(() => import('../../views/main/handlingcenter/order/list'));
const OrderDetailPage = React.lazy(() => import('../../views/main/handlingcenter/order/detail'));
const OrderCreatePage = React.lazy(() => import('../../views/main/handlingcenter/order/create'));
// 退课
const OrderRefundListPage = React.lazy(() => import('../../views/main/handlingcenter/orderRefund/list'));
const OrderRefundDetailPage = React.lazy(() => import('../../views/main/handlingcenter/orderRefund/detail'));
const OrderRefundCreatePage = React.lazy(() => import('../../views/main/handlingcenter/orderRefund/create'));

// 转课
const CourseTransferListPage = React.lazy(() => import('../../views/main/handlingcenter/transfer/list'));
const CourseTransferDetailPage = React.lazy(() => import('../../views/main/handlingcenter/transfer/detail'));
const CourseTransferCreatePage = React.lazy(() => import('../../views/main/handlingcenter/transfer/create'));

// 补费
const OrderSupplementListPage = React.lazy(() => import('../../views/main/handlingcenter/supplement/list'));
const OrderSupplementDetailPage = React.lazy(() => import('../../views/main/handlingcenter/supplement/detail'));
const OrderSupplementCreatePage = React.lazy(() => import('../../views/main/handlingcenter/supplement/create'));

// 财务中心-订单管理
const OrdercollectPage = React.lazy(() => import('../../views/main/financecenter/ordercollect'));
// 财务中心-工资管理
const PayslipPage = React.lazy(() => import('../../views/main/financecenter/payslip/list'));
const PayslipCreatePage = React.lazy(() => import('../../views/main/financecenter/payslip/create'));
const PayslipDetailPage = React.lazy(() => import('../../views/main/financecenter/payslip/detail'));
const PayslipItemPage = React.lazy(() => import('../../views/main/financecenter/payslip/payslipItem'));
const PayslipItemEditPage = React.lazy(() => import('../../views/main/financecenter/payslip/payslipItem/edit'));
const PayslipItemCreatePage = React.lazy(() => import('../../views/main/financecenter/payslip/payslipItem/create'));

// 财务中心-账单管理
const BillListPage = React.lazy(() => import('../../views/main/financecenter/bill/list'));
const BillCategoryPage = React.lazy(() => import('../../views/main/financecenter/bill/category'));

// 报读列表
const ClassHourPage = React.lazy(() => import('../../views/main/classHour/list'));

// 学生课时明细
// const ClassHourStudentPage = React.lazy(() => import('../../views/main/order/classHour/studentDetail'));

// 订单课时明细
// const ClassHourOrderPage = React.lazy(() => import('../../views/main/order/classHour/orderDetail'));

// 课时变更明细
const ClassHourChangeDetailPage = React.lazy(() => import('../../views/main/classHour/changeDetail'));

// 课时 续费助手
const ClassHourRenewPage = React.lazy(() => import('../../views/main/classHour/renew'));

// 课时 课时明细
const ClassHourDetail = React.lazy(() => import('../../views/main/classHour/classHourDetail'));

// 课时 补填课程
const SupplementCourse = React.lazy(() => import('../../views/main/classHour/supplementCourse'));

// 班级管理
const ClassManagePage = React.lazy(() => import('../../views/main/team/teamManage'));

// 班级详情
const ClassDetailPage = React.lazy(() => import('../../views/main/team/teamDetail'));

// 员工管理
const StaffPage = React.lazy(() => import('../../views/main/staff/list'));

// 员工详情页
const StaffDetailPage = React.lazy(() => import('../../views/main/staff/detail'));

// 排课管理
const ScheduleManagePage = React.lazy(() => import('../../views/main/scheduleManage/list'));

// 排课详情页
const ScheduleDetailPage = React.lazy(() => import('../../views/main/scheduleManage/detail'));

// 新建排课
const ScheduleAddPage = React.lazy(() => import('../../views/main/scheduleManage/add'));

// 复制排课
const ReplicateArrangePage = React.lazy(() => import('../../views/main/scheduleManage/replicateArrange'));

// 新建约课
const AddBookPage = React.lazy(() => import('../../views/main/scheduleManage/addBook'));

// 复制约课
const ReplicateBookPage = React.lazy(() => import('../../views/main/scheduleManage/replicateBook'));

// 招生统计
const LeadsstatPage = React.lazy(() => import('../../views/main/dataCenter/leadsstat'));

// 学生统计
const StudentstatPage = React.lazy(() => import('../../views/main/dataCenter/studentstat'));

// 排课统计
const ClassstatPage = React.lazy(() => import('../../views/main/dataCenter/classstat'));

// 课时统计
const ClasshourstatPage = React.lazy(() => import('../../views/main/dataCenter/classhourstat'));

// 消息统计
const NewsstatPage = React.lazy(() => import('../../views/main/dataCenter/newsstat'));

// 订单统计
const OrderstatPage = React.lazy(() => import('../../views/main/dataCenter/orderstat'));

// 账单统计
const BillStatPage = React.lazy(() => import('../../views/main/dataCenter/billstat'));

// 字典管理
const DictPage = React.lazy(() => import('../../views/main/setting/dict'));

// 用户权限管理
const UserAuthorityPage = React.lazy(() => import('../../views/main/setting/authority'));

// 其他
const OtherPage = React.lazy(() => import('../../views/main/setting/other'));

// 数据导入
const DataImport = React.lazy(() => import('../../views/main/setting/dataImport'));

// 数据导出
const DataExport = React.lazy(() => import('../../views/main/setting/dataExport'));

// 实验功能
const Experiment = React.lazy(() => import('../../views/main/setting/experiment/list'));

// 跨校区线索排重
const LeadsRemoveRepeat = React.lazy(() => import('../../views/main/setting/experiment/leadsRemoveRepeat'));

// 操作日志管理
const JournalPage = React.lazy(() => import('../../views/main/setting/journal'));

// 系统有效期
const OrgValidityPage = React.lazy(() => import('../../views/main/setting/validity'));

// 实名认证
const RealAuthorityPage = React.lazy(() => import('../../views/main/setting/realAuthority'));

// 微信支付商户list
const WechatpaymerchantList = React.lazy(() => import('../../views/main/setting/wxPay/list'));

// 微信支付商户编辑
const WechatpaymerchantEdit = React.lazy(() => import('../../views/main/setting/wxPay/edit'));

// 微信支付商户详情
const WechatpaymerchantDetail = React.lazy(() => import('../../views/main/setting/wxPay/detail'));

// export const MainLayoutRoutes = [
//   {
//     path: `${hasLeftSidePath}/shop`,
//     name: '机构概览',
//     icon: ShopIcon,
//     component: props => lazy(<ShopPage {...props} />),
//   },
//   {
//     path: `${hasLeftSidePath}/teachItem`,
//     name: '教务管理',
//     icon: TeachIcon,
//     component: (props) => {
//       const { route } = props;
//       return <>{renderRoutes(route.routes)}</>;
//     },
//     routes: [
//       {
//         path: `${hasLeftSidePath}/teachItem/scheduleManage`,
//         name: '排课管理',
//         exact: true,
//         component: props => lazy(<ScheduleManagePage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/teachItem/scheduleManage/add`,
//         belongPath: `${hasLeftSidePath}/teachItem/scheduleManage`,
//         name: '新建排课',
//         hidden: true,
//         component: props => lazy(<ScheduleAddPage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/teachItem/scheduleManage/detail`,
//         belongPath: `${hasLeftSidePath}/teachItem/scheduleManage`,
//         name: '排课详情页',
//         hidden: true,
//         component: props => lazy(<ScheduleDetailPage {...props} />),
//       },
//       // {
//       //   path: `${hasLeftSidePath}/teachItem/classManage`,
//       //   name: '班级管理',
//       //   component: props => lazy(<ClassManagePage {...props} />),
//       // },
//       // {
//       //   path: `${hasLeftSidePath}/teachItem/classroomManage`,
//       //   name: '教室管理',
//       //   component: props => lazy(<ClassRoomPage {...props} />),
//       // },
//       {
//         path: '*',
//         name: '404',
//         component: () => RedirectTo(),
//       },
//     ],
//   },
//   {
//     path: `${hasLeftSidePath}/order`,
//     name: '订单管理',
//     icon: OrderIcon,
//     component: (props) => {
//       const { route } = props;
//       return <>{renderRoutes(route.routes)}</>;
//     },
//     routes: [
//       {
//         path: `${hasLeftSidePath}/order/orderManage`,
//         exact: true,
//         name: '订单管理',
//         component: props => lazy(<OrderPage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/order/orderManage/detail`,
//         name: '订单详情页',
//         hidden: true,
//         belongPath: `${hasLeftSidePath}/order/orderManage`,
//         component: props => lazy(<OrderDetailPage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/order/orderManage/pay`,
//         name: '新建订单',
//         hidden: true,
//         belongPath: `${hasLeftSidePath}/order/orderManage`,
//         component: props => lazy(<OrderPayPage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/order/orderManage/refund`,
//         name: '订单退费页',
//         hidden: true,
//         belongPath: `${hasLeftSidePath}/order/orderManage`,
//         component: props => lazy(<OrderRefundPage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/order/classHourManage`,
//         exact: true,
//         name: '报读列表',
//         component: props => lazy(<ClassHourPage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/order/classHourManage/studentDetail`,
//         name: '学生课时明细',
//         hidden: true,
//         belongPath: `${hasLeftSidePath}/order/classHourManage`,
//         component: props => lazy(<ClassHourStudentPage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/order/classHourManage/orderDetail`,
//         name: '订单课时明细',
//         hidden: true,
//         belongPath: `${hasLeftSidePath}/order/classHourManage`,
//         component: props => lazy(<ClassHourOrderPage {...props} />),
//       },
//       {
//         path: '*',
//         name: '404',
//         component: () => RedirectTo(),
//       },
//     ],
//   },
//   {
//     path: `${hasLeftSidePath}/student`,
//     name: '学员管理',
//     icon: StudentIcon,
//     component: (props) => {
//       const { route } = props;
//       return <>{renderRoutes(route.routes)}</>;
//     },
//     routes: [
//       {
//         path: `${hasLeftSidePath}/student/students`,
//         name: '学生列表',
//         exact: true,
//         component: props => lazy(<StudentsPage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/student/students/detail`,
//         belongPath: `${hasLeftSidePath}/student/students`,
//         name: '学生详情',
//         hidden: true,
//         component: props => lazy(<StudentsDetailPage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/student/parents`,
//         name: '家长管理',
//         component: props => lazy(<ParentsPage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/student/teamManage`,
//         name: '班级管理',
//         component: props => lazy(<ClassManagePage {...props} />),
//       },
//       {
//         path: '*',
//         name: '404',
//         component: () => RedirectTo(),
//       },
//     ],
//   },
//   {
//     path: `${hasLeftSidePath}/leads`,
//     name: '线索管理',
//     icon: LeadsIcon,
//     component: (props) => {
//       const { route } = props;
//       return <>{renderRoutes(route.routes)}</>;
//     },
//     routes: [
//       {
//         path: `${hasLeftSidePath}/leads/leadsManage`,
//         name: '线索列表',
//         exact: true,
//         component: props => lazy(<LeadsPage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/leads/trialrecord`,
//         name: '试听管理',
//         exact: true,
//         component: props => lazy(<AuditionManage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/leads/leadsManage/detail`,
//         belongPath: `${hasLeftSidePath}/leads/leadsManage`,
//         name: '线索详情',
//         hidden: true,
//         component: props => lazy(<LeadsDetailPage {...props} />),
//       },
//       {
//         path: '*',
//         name: '404',
//         component: () => RedirectTo(),
//       },
//     ],
//   },
//   {
//     path: `${hasLeftSidePath}/course`,
//     name: '课程管理',
//     icon: ClassIcon,
//     component: (props) => {
//       const { route } = props;
//       return <>{renderRoutes(route.routes)}</>;
//     },
//     routes: [
//       {
//         path: `${hasLeftSidePath}/course/courseManage`,
//         name: '课程管理',
//         exact: true,
//         component: props => lazy(<CourseManagePage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/course/courseManage/add`,
//         belongPath: `${hasLeftSidePath}/course/courseManage`,
//         name: '新建课程',
//         hidden: true,
//         component: props => lazy(<CourseManageEditPage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/course/courseManage/edit`,
//         belongPath: `${hasLeftSidePath}/course/courseManage`,
//         name: '编辑管理',
//         hidden: true,
//         component: props => lazy(<CourseManageEditPage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/course/courseManage/detial`,
//         belongPath: `${hasLeftSidePath}/course/courseManage`,
//         name: '课程详情页',
//         hidden: true,
//         component: props => lazy(<CourseManageDetailPage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/course/courseOutline`,
//         exact: true,
//         name: '课程大纲',
//         component: props => lazy(<CourseOutlinePage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/course/courseOutline/detail`,
//         belongPath: `${hasLeftSidePath}/course/courseOutline`,
//         name: '大纲详情页',
//         hidden: true,
//         component: props => lazy(<CourseOutlineDetailPage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/course/courseware`,
//         exact: true,
//         name: '课件管理',
//         component: props => lazy(<CoursewarePage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/course/recordVideo`,
//         exact: true,
//         name: '录播视频管理',
//         component: props => lazy(<CourseVideoPage {...props} />),
//       },
//       {
//         path: '*',
//         name: '404',
//         component: () => RedirectTo(),
//       },
//     ],
//   },
//   // {
//   //   path: `${hasLeftSidePath}/online`,
//   //   name: '线上授课',
//   //   icon: OnlineClassIcon,
//   //   component: (props) => {
//   //     const { route } = props;
//   //     return <>{renderRoutes(route.routes)}</>;
//   //   },
//   //   routes: [
//   //     {
//   //       path: `${hasLeftSidePath}/online/classManage`,
//   //       name: '班级管理',
//   //       component: props => lazy(<ShopPage {...props} />),
//   //     },
//   //     {
//   //       path: '*',
//   //       name: '404',
//   //       component: () => RedirectTo(),
//   //     },
//   //   ],
//   // },
//   {
//     path: `${hasLeftSidePath}/teacher`,
//     exact: true,
//     name: '教师管理',
//     icon: TeacherIcon,
//     component: props => lazy(<TeacherPage {...props} />),
//     // component: props => lazy(<Ceshi {...props} />),
//   },
//   {
//     path: `${hasLeftSidePath}/teacher/detail`,
//     name: '教师详情页',
//     hidden: true,
//     belongPath: `${hasLeftSidePath}/teacher`,
//     component: props => lazy(<TeacherDetailPage {...props} />),
//   },
//   {
//     path: `${hasLeftSidePath}/staff`,
//     exact: true,
//     name: '员工管理',
//     icon: StaffIcon,
//     component: props => lazy(<StaffPage {...props} />)
//   },
//   {
//     path: `${hasLeftSidePath}/staff/detail`,
//     name: '员工详情页',
//     hidden: true,
//     belongPath: `${hasLeftSidePath}/staff`,
//     component: props => lazy(<StaffDetailPage {...props} />),
//   },
//   {
//     path: `${hasLeftSidePath}/classroom`,
//     name: '教室管理',
//     icon: ClassroomIcon,
//     component: props => lazy(<ClassRoomPage {...props} />),
//   },
//   {
//     path: `${hasLeftSidePath}/datacenter`,
//     name: '数据中心',
//     icon: DataCenterIcon,
//     component: (props) => {
//       const { route } = props;
//       return <>{renderRoutes(route.routes)}</>;
//     },
//     routes: [
//       {
//         path: `${hasLeftSidePath}/datacenter/studentstat`,
//         name: '学生统计',
//         component: props => lazy(<StudentstatPage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/datacenter/orderstat`,
//         name: '订单统计',
//         component: props => lazy(<OrderstatPage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/datacenter/classstat`,
//         name: '排课统计',
//         component: props => lazy(<ClassstatPage {...props} />),
//       },
//       {
//         path: '*',
//         name: '404',
//         component: () => RedirectTo(),
//       },
//     ],
//   },
//   {
//     path: `${hasLeftSidePath}/setting`,
//     name: '设置',
//     icon: SetIcon,
//     component: (props) => {
//       const { route } = props;
//       return <>{renderRoutes(route.routes)}</>;
//     },
//     routes: [
//       {
//         path: `${hasLeftSidePath}/setting/dict`,
//         name: '字典管理',
//         component: props => lazy(<DictPage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/setting/permission`,
//         name: '用户权限',
//         component: props => lazy(<UserAuthorityPage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/setting/auditLog`,
//         name: '操作日志',
//         component: props => lazy(<JournalPage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/setting/orgValidity`,
//         name: '系统有效期',
//         component: props => lazy(<OrgValidityPage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/setting/orgCert`,
//         name: '实名认证',
//         component: props => lazy(<RealAuthorityPage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/setting/wechatpaymerchant`,
//         name: '微信支付商户',
//         component: props => lazy(<WechatpaymerchantPage {...props} />),
//       },
//       {
//         path: `${hasLeftSidePath}/setting/other`,
//         name: '其他',
//         component: props => lazy(<OtherPage {...props} />),
//       },
//       {
//         path: '*',
//         name: '404',
//         component: () => RedirectTo(),
//       },
//     ],
//   },
//   {
//     path: '*',
//     name: '404',
//     component: () => RedirectTo(),
//   },
// ];

export const MainLayoutRoutes = [
  {
    path: `${hasLeftSidePath}/shop`,
    name: '机构概览',
    icon: ShopIcon,
    component: props => lazy(<ShopPage {...props} />),
  },
  {
    path: `${hasLeftSidePath}/leads`,
    name: '招生中心',
    icon: StudentIcon,
    component: (props) => {
      const { route } = props;
      return <>{renderRoutes(route.routes)}</>;
    },
    routes: [
      {
        path: `${hasLeftSidePath}/leads/infoAcquisition`,
        name: '采单管理',
        exact: true,
        component: props => lazy(<InfoAcquisition {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/infoAcquisition/add`,
        belongPath: `${hasLeftSidePath}/leads/infoAcquisition`,
        name: '新建采单码',
        hidden: true,
        component: props => lazy(<InfoAcquisitionEditPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/infoAcquisition/edit`,
        belongPath: `${hasLeftSidePath}/leads/infoAcquisition`,
        name: '编辑采单码',
        hidden: true,
        component: props => lazy(<InfoAcquisitionEditPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/infoAcquisition/detail`,
        belongPath: `${hasLeftSidePath}/leads/infoAcquisition`,
        name: '采单码详情',
        hidden: true,
        component: props => lazy(<InfoAcquisitionDetailPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/myInfoAcquisition`,
        name: '我的采单',
        exact: true,
        component: props => lazy(<MyInfoAcquisition {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/myInfoAcquisition/add`,
        belongPath: `${hasLeftSidePath}/leads/myInfoAcquisition`,
        name: '新建采单码',
        hidden: true,
        component: props => lazy(<InfoAcquisitionEditPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/myInfoAcquisition/edit`,
        belongPath: `${hasLeftSidePath}/leads/myInfoAcquisition`,
        name: '编辑采单码',
        hidden: true,
        component: props => lazy(<InfoAcquisitionEditPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/myInfoAcquisition/detail`,
        belongPath: `${hasLeftSidePath}/leads/myInfoAcquisition`,
        name: '采单码详情',
        hidden: true,
        component: props => lazy(<InfoAcquisitionDetailPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/onlineShop`,
        name: '线上招生',
        exact: true,
        component: props => lazy(<OnlineShop {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/onlineShop/orgInfo`,
        belongPath: `${hasLeftSidePath}/leads/onlineShop`,
        name: '机构信息',
        exact: true,
        hidden: true,
        component: props => lazy(<OrgInfo {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/onlineShop/saleCourse`,
        belongPath: `${hasLeftSidePath}/leads/onlineShop`,
        name: '线上售卖课程',
        exact: true,
        hidden: true,
        component: props => lazy(<SaleCourse {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/onlineShop/saleCourse/editCourse`,
        belongPath: `${hasLeftSidePath}/leads/onlineShop`,
        name: '编辑课程信息',
        exact: true,
        hidden: true,
        component: props => lazy(<EditCourse {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/onlineShop/seckill`,
        belongPath: `${hasLeftSidePath}/leads/onlineShop`,
        name: '惊喜秒杀',
        exact: true,
        hidden: true,
        component: props => lazy(<SeckillList {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/onlineShop/seckill/add`,
        belongPath: `${hasLeftSidePath}/leads/onlineShop`,
        name: '新建',
        exact: true,
        hidden: true,
        component: props => lazy(<EditSeckill {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/onlineShop/seckill/edit`,
        belongPath: `${hasLeftSidePath}/leads/onlineShop`,
        name: '编辑',
        exact: true,
        hidden: true,
        component: props => lazy(<EditSeckill {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/onlineShop/seckill/poster`,
        belongPath: `${hasLeftSidePath}/leads/onlineShop`,
        name: '海报',
        exact: true,
        hidden: true,
        component: props => lazy(<SeckillPoster {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/onlineShop/seckill/data`,
        belongPath: `${hasLeftSidePath}/leads/onlineShop`,
        name: '数据',
        exact: true,
        hidden: true,
        component: props => lazy(<SeckillData {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/onlineShop/pintuan`,
        belongPath: `${hasLeftSidePath}/leads/onlineShop`,
        name: '一起拼团',
        exact: true,
        hidden: true,
        component: props => lazy(<PinTuanList {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/onlineShop/pintuan/add`,
        belongPath: `${hasLeftSidePath}/leads/onlineShop`,
        name: '新建',
        exact: true,
        hidden: true,
        component: props => lazy(<EditPintuan {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/onlineShop/pintuan/edit`,
        belongPath: `${hasLeftSidePath}/leads/onlineShop`,
        name: '编辑',
        exact: true,
        hidden: true,
        component: props => lazy(<EditPintuan {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/onlineShop/pintuan/poster`,
        belongPath: `${hasLeftSidePath}/leads/onlineShop`,
        name: '海报',
        exact: true,
        hidden: true,
        component: props => lazy(<PinTuanPoster {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/onlineShop/pintuan/data`,
        belongPath: `${hasLeftSidePath}/leads/onlineShop`,
        name: '数据',
        exact: true,
        hidden: true,
        component: props => lazy(<PinTuanData {...props} />),
      },


      {
        path: `${hasLeftSidePath}/leads/onlineShop/baoming`,
        belongPath: `${hasLeftSidePath}/leads/onlineShop`,
        name: '活动报名',
        exact: true,
        hidden: true,
        component: props => lazy(<BaoMingList {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/onlineShop/baoming/add`,
        belongPath: `${hasLeftSidePath}/leads/onlineShop`,
        name: '新建',
        exact: true,
        hidden: true,
        component: props => lazy(<EditBaoMing {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/onlineShop/baoming/edit`,
        belongPath: `${hasLeftSidePath}/leads/onlineShop`,
        name: '编辑',
        exact: true,
        hidden: true,
        component: props => lazy(<EditBaoMing {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/onlineShop/baoming/poster`,
        belongPath: `${hasLeftSidePath}/leads/onlineShop`,
        name: '海报',
        exact: true,
        hidden: true,
        component: props => lazy(<BaoMingPoster {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/onlineShop/baoming/data`,
        belongPath: `${hasLeftSidePath}/leads/onlineShop`,
        name: '数据',
        exact: true,
        hidden: true,
        component: props => lazy(<BaoMingData {...props} />),
      },

      {
        path: `${hasLeftSidePath}/leads/leadsManage`,
        name: '线索管理',
        exact: true,
        component: props => lazy(<LeadsPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/leadsManage/detail`,
        belongPath: `${hasLeftSidePath}/leads/leadsManage`,
        name: '线索详情',
        hidden: true,
        component: props => lazy(<LeadsDetailPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/myLeads`,
        name: '我的线索',
        exact: true,
        component: props => lazy(<MyLeadsPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/myLeads/detail`,
        belongPath: `${hasLeftSidePath}/leads/myLeads`,
        name: '线索详情',
        hidden: true,
        component: props => lazy(<LeadsDetailPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/revisitManage`,
        name: '回访管理',
        exact: true,
        component: props => lazy(<RevisitManage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/leads/trialrecord`,
        name: '试听管理',
        exact: true,
        component: props => lazy(<AuditionManage {...props} />),
      },
      {
        path: '*',
        name: '404',
        component: () => RedirectTo(),
      },
    ],
  },
  /**
  {
    path: `${hasLeftSidePath}/order`,
    name: '办理中心',
    icon: OrderIcon,
    component: (props) => {
      const { route } = props;
      return <>{renderRoutes(route.routes)}</>;
    },
    routes: [
      {
        path: `${hasLeftSidePath}/order/orderManage`,
        exact: true,
        name: '订单管理',
        component: props => lazy(<OrderPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/order/orderManage/detail`,
        name: '订单详情页',
        hidden: true,
        belongPath: `${hasLeftSidePath}/order/orderManage`,
        component: props => lazy(<OrderDetailPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/order/orderManage/pay`,
        name: '新建订单',
        hidden: true,
        belongPath: `${hasLeftSidePath}/order/orderManage`,
        component: props => lazy(<OrderPayPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/order/orderManage/refund`,
        name: '订单退费页',
        hidden: true,
        belongPath: `${hasLeftSidePath}/order/orderManage`,
        component: props => lazy(<OrderRefundPage {...props} />),
      },
      {
        path: '*',
        name: '404',
        component: () => RedirectTo(),
      },
    ],
  },
  * */
  {
    path: `${hasLeftSidePath}/handlingcenter`,
    name: '办理中心',
    icon: OrderIcon,
    component: (props) => {
      const { route } = props;
      return <>{renderRoutes(route.routes)}</>;
    },
    routes: [
      {
        path: `${hasLeftSidePath}/handlingcenter`,
        exact: true,
        hidden: true,
        // name: '办理中心',
        component: props => lazy(<HandlingcenterPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/handlingcenter/order`,
        hidden: true,
        exact: true,
        name: '报名续费',
        belongPath: `${hasLeftSidePath}/handlingcenter`,
        component: props => lazy(<OrderListPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/handlingcenter/order/detail`,
        name: '报名续费详情',
        hidden: true,
        belongPath: `${hasLeftSidePath}/handlingcenter`,
        component: props => lazy(<OrderDetailPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/handlingcenter/order/create`,
        name: '办理报名续费',
        hidden: true,
        belongPath: `${hasLeftSidePath}/handlingcenter`,
        component: props => lazy(<OrderCreatePage {...props} />),
      },

      {
        path: `${hasLeftSidePath}/handlingcenter/refundClass`,
        name: '退课',
        exact: true,
        hidden: true,
        belongPath: `${hasLeftSidePath}/handlingcenter`,
        component: props => lazy(<OrderRefundListPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/handlingcenter/refundClass/detail`,
        name: '退课详情',
        hidden: true,
        belongPath: `${hasLeftSidePath}/handlingcenter`,
        component: props => lazy(<OrderRefundDetailPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/handlingcenter/refundClass/create`,
        name: '办理退课',
        hidden: true,
        belongPath: `${hasLeftSidePath}/handlingcenter`,
        component: props => lazy(<OrderRefundCreatePage {...props} />),
      },

      {
        path: `${hasLeftSidePath}/handlingcenter/transfer`,
        name: '转课',
        exact: true,
        hidden: true,
        belongPath: `${hasLeftSidePath}/handlingcenter`,
        component: props => lazy(<CourseTransferListPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/handlingcenter/transfer/detail`,
        name: '办理详情',
        hidden: true,
        belongPath: `${hasLeftSidePath}/handlingcenter`,
        component: props => lazy(<CourseTransferDetailPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/handlingcenter/transfer/create`,
        name: '发起转课',
        hidden: true,
        belongPath: `${hasLeftSidePath}/handlingcenter`,
        component: props => lazy(<CourseTransferCreatePage {...props} />),
      },

      {
        path: `${hasLeftSidePath}/handlingcenter/supplement`,
        name: '补费',
        exact: true,
        hidden: true,
        belongPath: `${hasLeftSidePath}/handlingcenter`,
        component: props => lazy(<OrderSupplementListPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/handlingcenter/supplement/detail`,
        name: '办理详情',
        hidden: true,
        belongPath: `${hasLeftSidePath}/handlingcenter`,
        component: props => lazy(<OrderSupplementDetailPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/handlingcenter/supplement/create`,
        name: '办理补费',
        hidden: true,
        belongPath: `${hasLeftSidePath}/handlingcenter`,
        component: props => lazy(<OrderSupplementCreatePage {...props} />),
      },

      {
        path: '*',
        name: '404',
        component: () => RedirectTo(),
      },
    ],
  },
  {
    path: `${hasLeftSidePath}/teachItem`,
    name: '教务中心',
    icon: TeachIcon,
    component: (props) => {
      const { route } = props;
      return <>{renderRoutes(route.routes)}</>;
    },
    routes: [
      {
        path: `${hasLeftSidePath}/teachItem/students`,
        name: '学员管理',
        exact: true,
        component: props => lazy(<StudentsPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/teachItem/myStudents`,
        name: '我的学员',
        exact: true,
        component: props => lazy(<MyStudentsPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/teachItem/myStudents/detail`,
        belongPath: `${hasLeftSidePath}/teachItem/myStudents`,
        name: '学员详情',
        hidden: true,
        component: props => lazy(<StudentsDetailPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/teachItem/students/detail`,
        belongPath: `${hasLeftSidePath}/teachItem/students`,
        name: '学员详情',
        hidden: true,
        component: props => lazy(<StudentsDetailPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/teachItem/teamManage`,
        name: '班级管理',
        exact: true,
        component: props => lazy(<ClassManagePage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/teachItem/teamManage/detail`,
        belongPath: `${hasLeftSidePath}/teachItem/teamManage`,
        name: '班级详情',
        hidden: true,
        component: props => lazy(<ClassDetailPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/teachItem/scheduleManage`,
        name: '排课管理',
        exact: true,
        component: props => lazy(<ScheduleManagePage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/teachItem/scheduleManage/addArrange`,
        belongPath: `${hasLeftSidePath}/teachItem/scheduleManage`,
        name: '新建排课',
        hidden: true,
        component: props => lazy(<ScheduleAddPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/teachItem/scheduleManage/replicateArrange`,
        belongPath: `${hasLeftSidePath}/teachItem/scheduleManage`,
        name: '复制排课',
        hidden: true,
        component: props => lazy(<ReplicateArrangePage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/teachItem/scheduleManage/replicateBook`,
        belongPath: `${hasLeftSidePath}/teachItem/scheduleManage`,
        name: '复制约课',
        hidden: true,
        component: props => lazy(<ReplicateBookPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/teachItem/scheduleManage/detail`,
        belongPath: `${hasLeftSidePath}/teachItem/scheduleManage`,
        name: '排课详情页',
        hidden: true,
        component: props => lazy(<ScheduleDetailPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/teachItem/classHourManage`,
        exact: true,
        name: '报读列表',
        component: props => lazy(<ClassHourPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/teachItem/scheduleManage/addBook`,
        belongPath: `${hasLeftSidePath}/teachItem/scheduleManage`,
        name: '新建约课',
        hidden: true,
        component: props => lazy(<AddBookPage {...props} />),
      },
      // {
      //   path: `${hasLeftSidePath}/teachItem/classHourManage/studentDetail`,
      //   name: '学生课时明细',
      //   hidden: true,
      //   belongPath: `${hasLeftSidePath}/teachItem/classHourManage`,
      //   component: props => lazy(<ClassHourStudentPage {...props} />),
      // },
      // {
      //   path: `${hasLeftSidePath}/teachItem/classHourManage/orderDetail`,
      //   name: '订单课时明细',
      //   hidden: true,
      //   belongPath: `${hasLeftSidePath}/teachItem/classHourManage`,
      //   component: props => lazy(<ClassHourOrderPage {...props} />),
      // },
      {
        path: `${hasLeftSidePath}/teachItem/classHourManage/changeDetail`,
        name: '课时变更明细',
        hidden: true,
        belongPath: `${hasLeftSidePath}/teachItem/classHourManage`,
        component: props => lazy(<ClassHourChangeDetailPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/teachItem/classHourManage/renew`,
        name: '续费助手',
        hidden: true,
        belongPath: `${hasLeftSidePath}/teachItem/classHourManage`,
        component: props => lazy(<ClassHourRenewPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/teachItem/classHourManage/classHourDetail`,
        name: '课时明细',
        hidden: true,
        belongPath: `${hasLeftSidePath}/teachItem/classHourManage`,
        component: props => lazy(<ClassHourDetail {...props} />),
      },
      {
        path: `${hasLeftSidePath}/teachItem/classHourManage/supplementCourse`,
        name: '补填课程',
        hidden: true,
        belongPath: `${hasLeftSidePath}/teachItem/classHourManage`,
        component: props => lazy(<SupplementCourse {...props} />),
      },

      // {
      //   path: `${hasLeftSidePath}/teachItem/classManage`,
      //   name: '班级管理',
      //   component: props => lazy(<ClassManagePage {...props} />),
      // },
      // {
      //   path: `${hasLeftSidePath}/teachItem/classroomManage`,
      //   name: '教室管理',
      //   component: props => lazy(<ClassRoomPage {...props} />),
      // },
      {
        path: '*',
        name: '404',
        component: () => RedirectTo(),
      },
    ],
  },
  // {
  //   path: `${hasLeftSidePath}/student`,
  //   name: '学员管理',
  //   icon: StudentIcon,
  //   component: (props) => {
  //     const { route } = props;
  //     return <>{renderRoutes(route.routes)}</>;
  //   },
  //   routes: [
  //     {
  //       path: `${hasLeftSidePath}/student/parents`,
  //       name: '家长管理',
  //       component: props => lazy(<ParentsPage {...props} />),
  //     },
  //     {
  //       path: '*',
  //       name: '404',
  //       component: () => RedirectTo(),
  //     },
  //   ],
  // },
  {
    path: `${hasLeftSidePath}/course`,
    name: '教研中心',
    icon: ClassIcon,
    component: (props) => {
      const { route } = props;
      return <>{renderRoutes(route.routes)}</>;
    },
    routes: [
      {
        path: `${hasLeftSidePath}/course/courseManage`,
        name: '课程管理',
        exact: true,
        component: props => lazy(<CourseManagePage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/course/courseManage/add`,
        belongPath: `${hasLeftSidePath}/course/courseManage`,
        name: '新建课程',
        hidden: true,
        component: props => lazy(<CourseManageEditPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/course/courseManage/edit`,
        belongPath: `${hasLeftSidePath}/course/courseManage`,
        name: '编辑课程',
        hidden: true,
        component: props => lazy(<CourseManageEditPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/course/courseManage/detial`,
        belongPath: `${hasLeftSidePath}/course/courseManage`,
        name: '课程详情页',
        hidden: true,
        component: props => lazy(<CourseManageDetailPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/course/courseOutline`,
        exact: true,
        name: '课程大纲',
        component: props => lazy(<CourseOutlinePage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/course/courseOutline/detail`,
        belongPath: `${hasLeftSidePath}/course/courseOutline`,
        name: '大纲详情页',
        hidden: true,
        component: props => lazy(<CourseOutlineDetailPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/course/courseware`,
        exact: true,
        name: '课件管理',
        component: props => lazy(<CoursewarePage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/course/recordVideo`,
        exact: true,
        name: '录播视频管理',
        component: props => lazy(<CourseVideoPage {...props} />),
      },
      {
        path: '*',
        name: '404',
        component: () => RedirectTo(),
      },
    ],
  },
  {
    path: `${hasLeftSidePath}/innermgt`,
    name: '内部管理',
    icon: ClassroomIcon,
    component: (props) => {
      const { route } = props;
      return <>{renderRoutes(route.routes)}</>;
    },
    routes: [
      {
        path: `${hasLeftSidePath}/innermgt/teacher`,
        name: '教师管理',
        exact: true,
        component: props => lazy(<TeacherPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/innermgt/teacher/detail`,
        name: '教师详情页',
        hidden: true,
        belongPath: `${hasLeftSidePath}/innermgt/teacher`,
        component: props => lazy(<TeacherDetailPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/innermgt/classroom`,
        name: '教室管理',
        exact: true,
        component: props => lazy(<ClassRoomPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/innermgt/staff`,
        exact: true,
        name: '员工管理',
        component: props => lazy(<StaffPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/innermgt/staff/detail`,
        name: '员工详情页',
        hidden: true,
        belongPath: `${hasLeftSidePath}/innermgt/staff`,
        component: props => lazy(<StaffDetailPage {...props} />),
      },
      {
        path: '*',
        name: '404',
        component: () => RedirectTo(),
      },
    ],
  },
  {
    path: `${hasLeftSidePath}/financecenter`,
    name: '财务中心',
    icon: FinancecenterIcon,
    component: (props) => {
      const { route } = props;
      return <>{renderRoutes(route.routes)}</>;
    },
    routes: [
      {
        path: `${hasLeftSidePath}/financecenter/ordercollect`,
        name: '订单管理',
        exact: true,
        component: props => lazy(<OrdercollectPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/financecenter/ordercollect/orderDetail`,
        name: '报名续费详情',
        hidden: true,
        belongPath: `${hasLeftSidePath}/financecenter`,
        component: props => lazy(<OrderDetailPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/financecenter/ordercollect/refundClassDetail`,
        name: '退课详情',
        hidden: true,
        belongPath: `${hasLeftSidePath}/financecenter`,
        component: props => lazy(<OrderRefundDetailPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/financecenter/ordercollect/supplementDetail`,
        name: '补费详情',
        hidden: true,
        belongPath: `${hasLeftSidePath}/financecenter`,
        component: props => lazy(<OrderSupplementDetailPage {...props} />),
      },

      {
        path: `${hasLeftSidePath}/financecenter/payslip`,
        name: '工资管理',
        exact: true,
        component: props => lazy(<PayslipPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/financecenter/payslip/create`,
        name: '制作工资单',
        hidden: true,
        exact: true,
        belongPath: `${hasLeftSidePath}/financecenter/payslip`,
        component: props => lazy(<PayslipCreatePage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/financecenter/payslip/copy`,
        name: '复制工资单',
        hidden: true,
        exact: true,
        belongPath: `${hasLeftSidePath}/financecenter/payslip`,
        component: props => lazy(<PayslipCreatePage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/financecenter/payslip/detail`,
        name: '工资单详情',
        hidden: true,
        exact: true,
        belongPath: `${hasLeftSidePath}/financecenter/payslip`,
        component: props => lazy(<PayslipDetailPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/financecenter/payslip/payslipItem`,
        name: '工资项管理',
        hidden: true,
        exact: true,
        belongPath: `${hasLeftSidePath}/financecenter/payslip`,
        component: props => lazy(<PayslipItemPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/financecenter/payslip/payslipItem/create`,
        name: '添加工资项',
        hidden: true,
        belongPath: `${hasLeftSidePath}/financecenter/payslip`,
        component: props => lazy(<PayslipItemCreatePage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/financecenter/payslip/payslipItem/edit`,
        name: '编辑工资项',
        hidden: true,
        belongPath: `${hasLeftSidePath}/financecenter/payslip`,
        component: props => lazy(<PayslipItemEditPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/financecenter/billList`,
        name: '账单管理',
        exact: true,
        component: props => lazy(<BillListPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/financecenter/bill/category`,
        name: '账单分类',
        exact: true,
        hidden: true,
        belongPath: `${hasLeftSidePath}/financecenter/billList`,
        component: props => lazy(<BillCategoryPage {...props} />),
      },
      {
        path: '*',
        name: '404',
        component: () => RedirectTo(),
      },
    ],
  },
  {
    path: `${hasLeftSidePath}/datacenter`,
    name: '数据中心',
    icon: DataCenterIcon,
    component: (props) => {
      const { route } = props;
      return <>{renderRoutes(route.routes)}</>;
    },
    routes: [
      {
        path: `${hasLeftSidePath}/datacenter/leadsstat`,
        name: '招生统计',
        component: props => lazy(<LeadsstatPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/datacenter/studentstat`,
        name: '学员统计',
        component: props => lazy(<StudentstatPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/datacenter/classstat`,
        name: '排课统计',
        component: props => lazy(<ClassstatPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/datacenter/classhourstat`,
        name: '课时统计',
        component: props => lazy(<ClasshourstatPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/datacenter/orderstat`,
        name: '订单统计',
        component: props => lazy(<OrderstatPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/datacenter/billstat`,
        name: '账单统计',
        component: props => lazy(<BillStatPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/datacenter/messagestat`,
        name: '消息统计',
        component: props => lazy(<NewsstatPage {...props} />),
      },
      {
        path: '*',
        name: '404',
        component: () => RedirectTo(),
      },
    ],
  },
  {
    path: `${hasLeftSidePath}/setting`,
    name: '设置',
    icon: SetIcon,
    component: (props) => {
      const { route } = props;
      return <>{renderRoutes(route.routes)}</>;
    },
    routes: [
      {
        path: `${hasLeftSidePath}/setting/dict`,
        name: '字典管理',
        component: props => lazy(<DictPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/setting/permission`,
        name: '用户权限',
        component: props => lazy(<UserAuthorityPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/setting/auditLog`,
        name: '操作日志',
        component: props => lazy(<JournalPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/setting/dataImport`,
        name: '数据导入',
        component: props => lazy(<DataImport {...props} />),
      },
      {
        path: `${hasLeftSidePath}/setting/dataExport`,
        name: '数据导出',
        component: props => lazy(<DataExport {...props} />),
      },
      {
        path: `${hasLeftSidePath}/setting/experiment/index`,
        name: '实验功能',
        component: props => lazy(<Experiment {...props} />),
      },
      {
        path: `${hasLeftSidePath}/setting/experiment/leadsRemoveRepeat`,
        name: '跨校区线索排重',
        hidden: true,
        component: props => lazy(<LeadsRemoveRepeat {...props} />),
      },
      {
        path: `${hasLeftSidePath}/setting/orgValidity`,
        name: '系统有效期',
        component: props => lazy(<OrgValidityPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/setting/orgCert`,
        name: '实名认证',
        component: props => lazy(<RealAuthorityPage {...props} />),
      },
      {
        path: `${hasLeftSidePath}/setting/wechatpaymerchant`,
        name: '微信支付商户',
        exact: true,
        component: props => lazy(<WechatpaymerchantList {...props} />),
      },
      {
        path: `${hasLeftSidePath}/setting/wechatpaymerchant/edit`,
        belongPath: `${hasLeftSidePath}/setting/wechatpaymerchant`,
        name: '申请开通',
        exact: true,
        hidden: true,
        component: props => lazy(<WechatpaymerchantEdit {...props} />),
      },
      {
        path: `${hasLeftSidePath}/setting/wechatpaymerchant/update`,
        belongPath: `${hasLeftSidePath}/setting/wechatpaymerchant`,
        name: '申请详情',
        exact: true,
        hidden: true,
        component: props => lazy(<WechatpaymerchantEdit {...props} />),
      },
      {
        path: `${hasLeftSidePath}/setting/wechatpaymerchant/detail`,
        belongPath: `${hasLeftSidePath}/setting/wechatpaymerchant`,
        name: '商户详情',
        exact: true,
        hidden: true,
        component: props => lazy(<WechatpaymerchantDetail {...props} />),
      },
      {
        path: `${hasLeftSidePath}/setting/other`,
        name: '其他',
        component: props => lazy(<OtherPage {...props} />),
      },
      {
        path: '*',
        name: '404',
        component: () => RedirectTo(),
      },
    ],
  },
  {
    path: '*',
    name: '404',
    component: () => RedirectTo(),
  },
];

export const otherPath = [];
