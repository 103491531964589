import { message } from 'antd';
import * as server from './server';

export default {
  state: {
    loading: false,
    list: [],
    orderDetail: {},

    orderInfoDetail: {},
    refundInfoDetail: {},

    refundInfoProgress: {},

    // refundInfoConfirmDetail: {},

    createButtonLoading: false,
    detailLoading: false,

    refreshTime: new Date().getTime(),

    ORDER_STATUS: [],
    REFUND_REASON: [],
    REFUND_CHANNEL: [],

    searchParams: {},
  },
  reducers: {
    setState(state, payload) {
      Object.keys(payload).forEach((item) => {
        state[item] = payload[item];
      });
    },
  },
  effects: () => ({
    // 创建订单
    async createOrder(payload, rootStates, cb) {
      this.setState({
        createButtonLoading: true,
      });
      const { code, data } = await server.createOrder(payload);
      if (code === 0) {
        message.success('创建成功');
        cb && cb(data);
      }
      this.setState({
        createButtonLoading: false,
      });
    },
    // 获取订单详情
    async getOrderInfo(payload, rootStates, cb) {
      this.setState({
        detailLoading: true,
      });
      const { code, data } = await server.getOrderInfo(payload);
      if (code === 0) {
        this.setState({
          orderDetail: data,
        });
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      });
    },
    async getOrderDetail(payload, rootStates, cb) {
      this.setState({
        detailLoading: true,
      });
      const { code, data } = await server.getOrderDetail(payload);
      if (code === 0) {
        this.setState({
          orderInfoDetail: data,
        });
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      });
    },
    // 线下支付
    async payOffline(payload, rootStates, cb) {
      const { code } = await server.payOffline(payload);
      if (code === 0) {
        cb && cb();
      } else {
        message.error('支付失败');
      }
    },

    // 获取订单状态
    // 字典项调用
    async getDict(payload, rootStates, cb) {
      const { code, data } = await server.getTypeSelect(payload);
      if (code === 0) {
        this.setState({
          [payload.code]: data,
        });
      }
      cb && cb();
    },

    // 退费详情
    async getRefundDetail(payload, rootStates, cb) {
      this.setState({
        detailLoading: true,
      });
      const { code, data } = await server.getRefundDetail(payload);
      if (code === 0) {
        this.setState({
          refundInfoDetail: data,
        });
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      });
    },

    // 发起退费
    async createRefund(payload, rootStates, cb) {
      this.setState({
        createButtonLoading: true,
      });
      const { code, data } = await server.createRefund(payload);
      if (code === 0) {
        cb && cb(data);
      }
      this.setState({
        createButtonLoading: false,
      });
    },

    // 退费确认详情
    async getRefundConfirmDetail(payload, rootStates, cb) {
      this.setState({
        detailLoading: true,
      });
      const { code, data } = await server.getRefundConfirmDetail(payload);
      if (code === 0) {
        this.setState({
          refundInfoDetail: data,
        });
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      });
    },

    // 退费待打款详情
    async getRefundOfflineDetail(payload, rootStates, cb) {
      this.setState({
        detailLoading: true,
      });
      const { code, data } = await server.getRefundOfflineDetail(payload);
      if (code === 0) {
        this.setState({
          refundInfoDetail: data,
        });
        cb && cb(data);
      }
      this.setState({
        detailLoading: false,
      });
    },

    // 发起退费
    async confirmRefund(payload, rootStates, cb) {
      this.setState({
        createButtonLoading: true,
      });
      const { code, data } = await server.confirmRefund(payload);
      if (code === 0) {
        cb && cb(data);
      }
      this.setState({
        createButtonLoading: false,
      });
    },

    // 已打款操作
    async cashRefund(payload, rootStates, cb) {
      this.setState({
        createButtonLoading: true,
      });
      const { code, data } = await server.cashRefund(payload);
      if (code === 0) {
        cb && cb(data);
      }
      this.setState({
        createButtonLoading: false,
      });
    },

    // 退费进度
    async refundProgress(payload, rootStates, cb) {
      const { code, data } = await server.refundProgress(payload);
      if (code === 0) {
        this.setState({
          refundInfoProgress: data || {},
        });
        cb && cb(data);
      }
    },

    // 刷新页面
    refresh() {
      this.setState({
        refreshTime: new Date().getTime(),
      });
    },

    // list 页面 search 字段
    setSearchParams(payload) {
      this.setState({
        searchParams: payload,
      });
    },
  }),
};
