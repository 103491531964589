// 手机正则表达式
// const phoneReg = /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/;

// 开头为 1 长度是 11 的数字
const phoneReg = /^1[0-9]{10}$/;

// 西班牙电话判断
const spainPhoneReg = /^6\d{8}$/;

const homePhoneReg = /^0\d{2,3}-\d{7,8}$/;

// 包含数字及字母 8 -20 位
const wordAndNumReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;

// 邮箱格式
const mailReg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;

// 判断一个数是否为正整数
const positiveNumReg = /^[0-9]*[1-9][0-9]*$/;

// 判断一个数字或含两位小数
const moneyReg = /^\d+(\.\d{0,2})?$/;

export {
  phoneReg,
  spainPhoneReg,
  homePhoneReg,
  wordAndNumReg,
  mailReg,
  positiveNumReg,
  moneyReg,
};
