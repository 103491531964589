import request from '@/service';

// 获取下拉类型字典项
export async function getTypeSelect(params) {
  return request('/organization-service/dict/get_drop_down_list', {
    method: 'get',
    data: params,
  });
}

// 新建订单
export async function createOrder(params) {
  return request('/trade-service/order_info/create_order_info_v2', {
    method: 'post',
    data: params,
  });
}
// 获取收费标准
export async function listChargeStandardByCourseId(params) {
  return request('/curriculum-service/course_info/list_charge_standard_by_course_id', {
    method: 'get',
    data: params,
  });
}

// 获取订单信息
export async function getOrderInfo(params) {
  return request('/trade-service/order_info/get_order_simple_info', {
    method: 'get',
    data: params,
  });
}

// 线下付款
export async function payOffline(params) {
  return request('/trade-service/order_info/pay_order_with_offline', {
    method: 'post',
    data: params,
  });
}

// 获取订单详细信息
export async function getOrderDetail(params) {
  return request('/trade-service/order_info/get_order_detail', {
    method: 'get',
    data: params,
  });
}

// 退费预览
export async function getRefundDetail(params) {
  return request('/trade-service/order_refund/order_refund_preview', {
    method: 'get',
    data: params,
  });
}

// 发起退费
export async function createRefund(params) {
  return request('/trade-service/order_refund/order_refund_create', {
    method: 'post',
    data: params,
  });
}

// 确认退费预览
export async function getRefundConfirmDetail(params) {
  return request('/trade-service/order_refund/order_refund_confirm_preview', {
    method: 'get',
    data: params,
  });
}

// 确认待打款预览
export async function getRefundOfflineDetail(params) {
  return request('/trade-service/order_refund/order_refund_with_offline_preview', {
    method: 'get',
    data: params,
  });
}

// 确认退费
export async function confirmRefund(params) {
  return request('/trade-service/order_refund/order_refund_confirm', {
    method: 'post',
    data: params,
  });
}

// 已打款操作
export async function cashRefund(params) {
  return request('/trade-service/order_refund/order_refund_with_offline_remit', {
    method: 'post',
    data: params,
  });
}

// 退费进度
export async function refundProgress(params) {
  return request('/trade-service/order_refund/order_refund_progress', {
    method: 'get',
    data: params,
  });
}

// 获取微信支付二维码
export async function getQrCodeForWx(params) {
  return request('/trade-service/order_info/pay_order_with_native_we_chat', {
    method: 'get',
    data: params,
  });
}

// 微信支付轮训接口
export async function pollOrderPay(params) {
  return request('/trade-service/order_info/poll_order_pay_status', {
    method: 'get',
    data: params,
  });
}

// 获取退费原因
export async function getRefundReasonTypeList(params) {
  return request('/trade-service/refund_reason_code/list_all', {
    method: 'get',
    data: params,
  });
}

// 全量员工列表
export async function getListAllStaffInfo(params) {
  return request('/person-service/staff/list_all_no_departure_staff_info', {
    method: 'get',
    data: params,
  });
}

// 设置销售人员
export async function set_sales(params) {
  return request('/trade-service/order_info/set_sales', {
    method: 'post',
    data: params,
  });
}
