import request from '@/service';

// 获取已经覆盖到的学科
export async function getAllcurrentSubjects(params) {
  return request('/organization-service/dashboard/get_cover_subject', {
    method: 'get',
    data: params,
  });
}

// 设置关注学科
export async function setUserSubject(params) {
  return request('/organization-service/dashboard/set_attention_subject', {
    method: 'post',
    data: params,
  });
}

// 获取今日课量
export async function getTodayClass(params) {
  return request('/curriculum-service/dashboard/get_today_class', {
    method: 'get',
    data: params,
  });
}

// 获取线上线下订单数量
export async function getOrderNum(params) {
  return request('/trade-service/dashboard/get_order_count_for_order_source_type', {
    method: 'get',
    data: params,
  });
}

// 获取每月订单数量
export async function getOrderNumByMonth(params) {
  return request('/trade-service/dashboard/get_order_count_for_current_year', {
    method: 'get',
    data: params,
  });
}

// 获取每月在学学员数量
export async function getLearningStudents(params) {
  return request('/person-service/dashboard/get_learning_student_count_for_current_year', {
    method: 'get',
    data: params,
  });
}

// 获取待办事项数据
export async function get_agenda_item(params) {
  return request('/person-service/dashboard/get_agenda_item', {
    method: 'get',
    data: params,
  });
}

// 获取每月订单数量
export async function get_order_count(params) {
  return request('/trade-service/dashboard/get_order_count_v2', {
    method: 'get',
    data: params,
  });
}

// 获取每月排课数量
export async function get_class_count(params) {
  return request('/curriculum-service/dashboard/get_class_count', {
    method: 'get',
    data: params,
  });
}

// 获取每月学员数量
export async function get_student_count(params) {
  return request('/person-service/dashboard/get_student_count', {
    method: 'get',
    data: params,
  });
}

// 获取每月课时数量
export async function get_class_hour_count(params) {
  return request('/class-hour-service/dashboard/get_class_hour_count_2', {
    method: 'get',
    data: params,
  });
}
